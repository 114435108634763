import apiClient from "@/api/apiClient";

// initial state
const state = {
    improvements: [],
}

// getters
const getters = {

}

// actions
const actions = {
    getImprovements({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.improvements.length >= 1) {
            return state.improvements;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/improvements')
            .then(response => {
                commit('SET_IMPROVEMENTS', response.data);
                return response.data;
            })
    },
    getImprovementDetail({state, dispatch}, { number }) {

        let findById = (improvements) => {
            return improvements.find(improvement => improvement.number === number);
        }

        if (state.improvements.length === 0) {
            return dispatch('getImprovements').then(improvements => {
                return findById(improvements);
            });
        } else {
            return findById(state.improvements);
        }
    },
    addImprovement({dispatch}, formData) {
        return apiClient.post('/improvements/new', formData).then(response => {
            return dispatch('getImprovements', { forceReload: true }).then(() => {
                return response.data;
            });
        })
    },
    addImprovementComment({dispatch, state}, { improvement, comment, sendMail }) {
        let formData = new FormData();
        formData.set('comment', comment);
        formData.set('sendMail', sendMail);
        return apiClient.post('/improvements/'+improvement.number+'/comment', formData).then(() => {
            return dispatch('getImprovements', { forceReload: true }).then(() => {
                return state.improvements.find(i => i.number === improvement.number);
            });
        })
    },
    updateImprovement({dispatch, state}, { improvement, formData }) {
        return apiClient.post('/improvements/'+improvement.number+'/update', formData).then(() => {
            return dispatch('getImprovements', { forceReload: true }).then(() => {
                return state.improvements.find(i => i.number === improvement.number);
            });
        })
    },
    updateProgress({dispatch, state}, { improvement, progress, progressDescription }) {
        let formData = new FormData();
        formData.set('progress', progress);
        formData.set('progressDescription', progressDescription);
        return apiClient.post('/improvements/'+improvement.number+'/update-progress', formData).then(() => {
            return dispatch('getImprovements', { forceReload: true }).then(() => {
                return state.improvements.find(i => i.number === improvement.number);
            });
        })
    },
    // updateImages({dispatch, state}, { improvement, improvementImages }) {
    //     let formData = new FormData();
    //     formData.set('improvementImages', improvementImages);
    //     return apiClient.post('/improvements/'+improvement.number+'/update-images', formData).then(() => {
    //         return dispatch('getImprovements', { forceReload: true }).then(() => {
    //             return state.improvements.find(i => i.number === improvement.number);
    //         });
    //     })
    // },
    updateReady({dispatch, state}, {improvement, ready}) {
        let formData = new FormData();
        formData.set('ready', ready);
        return apiClient.post('/improvements/'+improvement.number+'/update-ready', formData).then(() => {
            return dispatch('getImprovements', {forceReload: true}).then(() => {
                return state.improvements.find(i => i.number === improvement.number);
            })
        });
    }
}

// mutations
const mutations = {
    SET_IMPROVEMENTS(state, improvements) {
       state.improvements = improvements;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
